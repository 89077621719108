.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(238, 238, 222, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  transition: 0.4s;
}

.modal.active {
  transform: scale(1);
}
