.input-city {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  font-size: 30px;
  width: 100%;
  height: 67px;
  padding: 0 232px 0 32px;

}

.container-city {
  position: relative;
  display: none;
}

.btn-city {
  position: absolute;
  top: 36px;
  right:25px;
  border: none;
  background: none;
  text-transform: uppercase;
  color: #1086FF;
  font-size: 30px;
}

