
 .posts-wrapper {
   margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.posts-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 2000px;
  flex-wrap: wrap;
  flex-grow: 2;
}

.btn {
  width: 150px;
}

 .posts-loader {
  margin-top: 15%;
 }